import React, { useState } from 'react';
import { GlobalContext } from './src/components/GlobalContext.tsx';


const GlobalContextProvider = (props) => {
  const [isEventOpen, setIsEventOpen] = useState(true);
  const { children } = props;
  return (
    <GlobalContext.Provider value={{ isEventOpen: isEventOpen, setIsEventOpen: setIsEventOpen }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>{element}</GlobalContextProvider>
);
